import { SetBankAccountParams } from '../api';

export const mapSetAchPayload = (params: SetBankAccountParams['payload']) => {
  switch (params.type) {
    case 'manual': {
      return {
        account_number: params.account,
        routing_number: params.routing,
        account_type: params.accountType.toLowerCase(),
        account_owner_name: params.owner,
      };
    }
    case 'plaid': {
      return {
        public_token: params.token,
        account_id: params.account,
      };
    }
    case 'banno': {
      return {
        id: params.id,
      };
    }
    case 'q2': {
      return {
        account_number: params.account,
        routing_number: params.routing,
      };
    }
  }
};
