import { PendingDirection, PendingItem, PendingItemDTO } from '../pendging';
import { mapNumberDto } from '~/shared/dtoMappers';
import { mapBankAccountDto } from '~/entities/ach';

const mapDepositDirection = (dto: PendingItemDTO['direction']) => {
  switch (dto) {
    case 'INCOMING':
    case 'incoming': {
      return PendingDirection.DEPOSIT;
    }
    case 'OUTGOING':
    case 'outgoing':
      return PendingDirection.WITHDRAW;
  }
};

export const mapPendingTransferDtoToDomain = (dto: PendingItemDTO): PendingItem => ({
  id: dto.id,
  amount: mapNumberDto(dto.amount),
  direction: mapDepositDirection(dto.direction),
  createdAt: new Date(dto.created_at),
  isSellAll: Boolean(dto.sell_all),
  portfolioId: dto.portfolio_id,
  bankAccountId: dto.bank_account_id,
  bankAccount: dto.bank_account ? mapBankAccountDto(dto.bank_account) : undefined,
});
