import { DefaultRootState } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { ProfileSettingsValue } from '@api/schemas/profile';
import { ApiError } from '@models/ApiError';

export const profileSettingsSelector = (state: DefaultRootState) => state.Settings.data;

export const userSettingsLoadingSelector = (state: DefaultRootState) => state.Settings.isLoading;

export const useSettingsEq = RD.getEq<ApiError, ProfileSettingsValue>(
  {
    equals: (e1, e2) => e1.code === e2.code,
  },
  {
    equals: (a, b) => {
      return (
        a.portfolioOnboardingPassed === b.portfolioOnboardingPassed &&
        a.selfDirectedPortfolioOnboardingPassed === b.selfDirectedPortfolioOnboardingPassed &&
        a.preMadePortfolioOnboardingPassed === b.preMadePortfolioOnboardingPassed &&
        a.lastActivePortfolioId === b.lastActivePortfolioId &&
        a.enableRemovePortfolio === b.enableRemovePortfolio
      );
    },
  },
);
