import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import * as F from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as RD from '@devexperts/remote-data-ts';

import { getLastDigits } from '@utils/getLastDigits.helper';
import { ACHRelationship } from '@models/transfers';

import { editACHSelector, achSelector, achAccountsSelector } from '../api/selectors';

export const useActiveACHRelationship = () => {
  const editACH = useSelector(editACHSelector);
  const achRD = useSelector(achSelector);
  const accountsPlainRD = useSelector(achAccountsSelector);
  const accountsPlain = useMemo(
    () =>
      F.pipe(
        accountsPlainRD,
        RD.getOrElse((): ACHRelationship[] => []),
      ),
    [accountsPlainRD],
  );

  return useMemo(
    () =>
      F.pipe(
        editACH,
        O.map((editValue) => {
          switch (editValue.type) {
            case 'manual':
              return {
                id: editValue.account,
                displayName: editValue.accountType,
                type: editValue.accountType as any,
                lastDigits: getLastDigits(editValue.account),
                portfolioId: '',
                // createdAt: mapIsoDate(editValue.created_at),
                balance: undefined,
                connectionType: 'manual' as const,
              };

            case 'plaid':
              return {
                id: editValue.account,
                displayName: editValue.accountType,
                type: editValue.accountType as any,
                lastDigits: getLastDigits(editValue.account),
                portfolioId: '',
                // createdAt: mapIsoDate(editValue.created_at),
                balance: undefined,
                connectionType: 'plaid' as const,
              };
            case 'banno':
            case 'q2':
              return {
                id: editValue.account,
                displayName: editValue.accountType,
                type: editValue.accountType as any,
                lastDigits: getLastDigits(editValue.account),
                portfolioId: '',
                // createdAt: mapIsoDate(editValue.created_at),
                balance: editValue.balance,
                connectionType: 'platform' as const,
              };
          }
        }),
        O.alt(() => RD.toOption(achRD)),
        O.map((ach) => ({
          ...ach,
          balance:
            ach.balance ||
            accountsPlain.find((plainAccount) => plainAccount.account_number.endsWith(ach.lastDigits))?.balance,
        })),
      ),
    [editACH, achRD, accountsPlain],
  );
};

export const useActiveAch = () => {
  const activeACHRelationship = useActiveACHRelationship();

  return useMemo(
    () =>
      F.pipe(
        activeACHRelationship,
        O.map((ach) => ({
          destinationAddress: ach.lastDigits,
          destinationType: ach.type,
          balance: ach.balance,
        })),
      ),
    [activeACHRelationship],
  );
};

export const useDestination = () => {
  const activeACH = useActiveAch();

  return useMemo(
    () =>
      F.pipe(
        activeACH,
        O.getOrElse(() => ({
          destinationAddress: '',
          destinationType: '' as string | undefined,
        })),
      ),
    [activeACH],
  );
};
